import React, { ReactNode } from "react";
import { PageProps, navigate } from "gatsby";
import SEO from "../atoms/seo";
import Layout from "../layout";
import {
  FeedbackProvider,
  IFeedbackState
} from "../../utils/contexts/feedbackContext";

export type BaseWrapperProps = {
  initialFeedbackState?: IFeedbackState;
  crumbLabel?: string;
  children?: ReactNode;
  SEOtitle?: string;
} & Pick<PageProps, "location">;

export default function BaseWrapper({
  initialFeedbackState,
  crumbLabel,
  SEOtitle,
  location,
  children
}: BaseWrapperProps) {
  return (
    <FeedbackProvider initial={initialFeedbackState}>
      <Layout location={location} crumbLabel={crumbLabel} navigate={navigate}>
        <SEO title={SEOtitle} />
        {children}
      </Layout>
    </FeedbackProvider>
  );
}