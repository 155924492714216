import React, { ReactElement, useState } from "react";
import { Element } from "domhandler/lib/node";
import parse, { HTMLReactParserOptions } from "html-react-parser";
import {
  createHtmlParserOptions,
  createLocalizedLink,
  isLinkTag,
  LinkType,
} from "../../../utils/htmlParser";
import { convertPageIdToPath, findCountryInPathname } from "../../../utils/path";
import { CountryKey, getCountryLabel, countries } from "../../../translations/countries";
import Links, { ListLink } from "../../molecules/links";
import { findTranslation } from "./index";
import { FC } from "react";
import { TranslationKey } from "../../../translations/languages";
import { rhythm, colorThemes } from "../../../utils/typography";
import styled from "styled-components";
import { darken, transparentize } from "polished";


const {
  separator: { primary: separatorPrimary },
  siteMap: { primary: siteMapPrimary },
} = colorThemes;

type ViewpointLinksProps = {
  languageCode: string;
  a_denmark?: string;
  a_finland?: string;
  a_iceland?: string;
  a_norway?: string;
  a_sweden?: string;
  b_denmark?: string;
  b_iceland?: string;
  b_finland?: string;
  b_norway?: string;
  b_sweden?: string;
  country_a_header?: string;
  country_b_header?: string;
  viewpoint_selection_title?: string;
};

const parseLinks = (
  opt: HTMLReactParserOptions,
  languageCode: string,
  html: string
) => {
  const links: LinkType[] = [];
  const parserOptions = {
    ...opt,
    replace: (domNode) => {
      if (domNode instanceof Element) {
        // Replace internal links with Gatsby links and add localization to the URI path.
        if (isLinkTag(domNode)) {
          const link = createLocalizedLink(domNode, languageCode);
          links.push(link);
        }
      }
    },
  };
  parse(html, parserOptions);

  return links;
};

const ViewpointContainer = styled.div`
  border-left: ${rhythm(0.1)} solid ${transparentize(0.3, siteMapPrimary)};
  padding-left: ${rhythm(2)};
  margin-bottom: ${rhythm(2)};
`;

const CountriesContainer = styled.div`
  border-left: ${rhythm(0.125)} solid ${darken(0.1, separatorPrimary)};
  padding-left: ${rhythm(2)};
  margin-bottom: ${rhythm(2)};
`;

const StyledLinks = styled(Links)`
  border-left: ${rhythm(0.1)} solid ${transparentize(0.3, siteMapPrimary)};
  padding-left: ${rhythm(2)};
  margin-bottom: ${rhythm(2)};
`;

const CountryLinks: FC<{
  languageCode: TranslationKey;
  countries: Record<CountryKey, LinkType[]>;
  header: string | undefined;
}> = ({ languageCode, countries, header }) => {
  return (
    <ViewpointContainer>
      {Object.entries(countries).map(([country, links], ind) => {
        const countryLabel = getCountryLabel(languageCode, country as CountryKey);
        const title = header ? `${header}: ${countryLabel}` : countryLabel;
        return (
          <div key={`div-${ind}`}>
            <h4 aria-hidden="true">{title}</h4>
            <StyledLinks links={links} aria-label={title} />
            <br />
          </div>
        );
      })}
    </ViewpointContainer>
  );
};

type SingleViewpointLink = {
  country: CountryKey;
  viewpoint: 'a' | 'b';
  component: ReactElement;
}
export const SingleLayerViewpointLinks: FC<{
  languageCode: TranslationKey;
  topic: any;
  ariaLabel?: string;
}> = ({ languageCode, topic, ariaLabel }) => {
  const links: SingleViewpointLink[] = topic?.pages?.map((page, index) => {
    const pathParts = page.id.split('-');

    const aPointPath = page.viewpoint.country_a_page_id_template.split('-');
    const bPointPath = page.viewpoint.country_b_page_id_template.split('-');

    const countryInPath = findCountryInPathname(page.id);

    if (!countryInPath || pathParts.length < 3) {
      return null;
    }

    const countryLabel = getCountryLabel(languageCode as TranslationKey, countryInPath as CountryKey);
    if (aPointPath[0] === pathParts[0] && aPointPath[2] === pathParts[2]) {
      const title = findTranslation(languageCode, page.viewpoint);
      const text = `${title?.country_a_header}: ${countryLabel}`;

      return {
        country: countryInPath,
        viewpoint: 'a',
        component: (
          <ListLink
            link={{
              to: convertPageIdToPath(languageCode, page.id),
              text: text ?? "",
            }}
            key={`listLink-${index}`}
          />
        )
      }
    } else if (bPointPath[0] === pathParts[0] && bPointPath[2] === pathParts[2]) {
      const title = findTranslation(languageCode, page.viewpoint);
      const text = `${title.country_b_header}: ${countryLabel}`;
      return {
        country: countryInPath,
        viewpoint: 'b',
        component: (
          <ListLink
            link={{
              to: convertPageIdToPath(languageCode, page.id),
              text: text ?? "",
            }}
            key={`listLink-${index}`}
          />
        )
      }
    } else {
      return null;
    }
  });

  let ordered: SingleViewpointLink[] = [] as SingleViewpointLink[];
  Object.keys(countries).forEach((country) => {
    const countryLinks = links.filter((l) => {
      if (!l) return false;
      if (l.country === country) {
        return true;
      } else {
        return false;
      }
    })
    const aLink = countryLinks.filter((cl) => cl.viewpoint === 'a');
    if (aLink && aLink.length > 0)
      ordered = ordered.concat(aLink);
    const bLink = countryLinks.filter((cl) => cl.viewpoint === 'b');
    if (bLink && bLink.length > 0)
      ordered = ordered.concat(bLink);
  });

  if (ordered) {
    return (<ViewpointContainer>
      <nav aria-label={ariaLabel}>
        {ordered.map((c) => c.component)}
      </nav>
    </ViewpointContainer>)
  } else return null
};

export const ViewpointLinks: FC<ViewpointLinksProps> = ({
  languageCode,
  viewpoint_selection_title,
  country_a_header,
  a_denmark,
  a_finland,
  a_iceland,
  a_norway,
  a_sweden,
  country_b_header,
  b_denmark,
  b_iceland,
  b_finland,
  b_norway,
  b_sweden,
}) => {
  const [initialParserOptions] = useState(
    createHtmlParserOptions(languageCode)
  );

  const aContents: Record<CountryKey, LinkType[]> = {
    denmark: parseLinks(initialParserOptions, languageCode, a_denmark ?? ""),
    finland: parseLinks(initialParserOptions, languageCode, a_finland ?? ""),
    iceland: parseLinks(initialParserOptions, languageCode, a_iceland ?? ""),
    norway: parseLinks(initialParserOptions, languageCode, a_norway ?? ""),
    sweden: parseLinks(initialParserOptions, languageCode, a_sweden ?? ""),
  };

  const bContents: Record<CountryKey, LinkType[]> = {
    denmark: parseLinks(initialParserOptions, languageCode, b_denmark ?? ""),
    finland: parseLinks(initialParserOptions, languageCode, b_finland ?? ""),
    iceland: parseLinks(initialParserOptions, languageCode, b_iceland ?? ""),
    norway: parseLinks(initialParserOptions, languageCode, b_norway ?? ""),
    sweden: parseLinks(initialParserOptions, languageCode, b_sweden ?? ""),
  };

  return (
    <>
      <h3>{viewpoint_selection_title}</h3>
      <CountryLinks
        languageCode={languageCode as TranslationKey}
        countries={aContents}
        header={country_a_header}
      />
      <CountryLinks
        languageCode={languageCode as TranslationKey}
        countries={bContents}
        header={country_b_header}
      />
    </>
  );
};

export default ViewpointLinks;
