import React, { ReactNode, useEffect } from "react";
import { PageProps, navigate } from "gatsby";
import { useLanguageState, useLanguageDispatch } from "../utils/contexts/languageContext";
import { Main, PageContainer } from "./atoms/pageContent";
import NavigationBar from "./molecules/navigationBar";
import Footer from "./molecules/footer";
import Breadcrumb from "../components/atoms/breadcrumb";
import LanguagesMenu from "./organisms/languages/languagesMenu";
import LinkToContent from "../components/atoms/contentlink";
import { TranslationKey } from "../translations/languages";
import useIsClient from "../utils/hooks/isClient"
import {findCountryCodeInPathName} from "../utils/path";

export type LayoutProps = {
  crumbLabel?: string;
  children?: ReactNode;
} & Pick<PageProps, "location" | "navigate">;

export default function Layout({
  location,
  children,
  crumbLabel,
}: LayoutProps) {
  const { isClient, key } = useIsClient();
  const { languageCode: currentLanguageCode } = useLanguageState();
  const setLanguage = useLanguageDispatch();
  // Navigate to language selection if no language is set.
  useEffect(() => {
    const codeInPath = findCountryCodeInPathName(location.pathname);

    if(codeInPath && codeInPath != currentLanguageCode) {
      setLanguage({languageCode: codeInPath})
    }

    if (!currentLanguageCode && !codeInPath) {
      navigate("/");
    }

  }, [navigate]);

  if ( !isClient ) return null;
  return (
    <PageContainer>
      <div key={key}>
        <LinkToContent languageCode={currentLanguageCode as TranslationKey} />
        <NavigationBar>
          <LanguagesMenu />
        </NavigationBar>
        <Breadcrumb location={location} crumbLabel={crumbLabel} />
      </div>
      <Main id="content">{children}</Main>
      <div>
        <Footer />
      </div>
    </PageContainer>
  );
}
