import React from "react";
import { PageProps } from "gatsby";
import BaseWrapper from "../components/molecules/BaseWrapper";
import SiteMap from "../components/organisms/siteMap";
import { getTranslation } from "../translations/sitemap";
import { TranslationKey } from "../translations/languages";

export type LanguageCode = {
  languages_code: {
    code: string;
  };
};

type PresentationOrder = {
  presentation_order: number;
};

type Viewpoint = {
  translations: ViewpointTranslation[];
};

type ViewpointTranslation = LanguageCode & {
  country_a_header: string;
  country_b_header: string;
  viewpoint_selection_title: string;
};

export type ViewPointTopic = PresentationOrder & {
  translations: ViewpointTopicTranslation[];
  viewpoint: Viewpoint;
};

type ViewpointTopicTranslation = LanguageCode & {
  a_denmark: string;
  a_finland: string;
  a_iceland: string;
  a_norway: string;
  a_sweden: string;
  b_denmark: string;
  b_iceland: string;
  b_finland: string;
  b_norway: string;
  b_sweden: string;
};

export type Topic = PresentationOrder & {
  topic_page: {
    id: string;
  };
  translations: TopicTranslation[];
  pages: any;
};

type TopicTranslation = LanguageCode & {
  topic_header: string;
};

export type TaxTopic = PresentationOrder & {
  id: string;
  translations: TaxTopicTranslation[];
};

export type TaxTopicTranslation = LanguageCode & {
  tax_topic_header: string;
};

export type SubTopic = PresentationOrder & {
  sub_topic_page: {
    id: string;
  };
  translations: SubTopicTranslation[];
};

type SubTopicTranslation = LanguageCode & {
  sub_topic_header: string;
};

export type FooterLink = PresentationOrder & {
  url: string;
  translations: FooterLinkTranslation[];
};

type FooterLinkTranslation = LanguageCode & {
  link_text: string;
};

type PageContext = {
  languageCode: string;

  taxTopics: TaxTopic[];
  viewpointTopics: ViewPointTopic[];
  topics: Topic[];
  subTopics: SubTopic[];
  footerLinks: FooterLink[];
};

const PageTemplate = (data: PageProps) => {
  const { pageContext, location } = data;
  const { languageCode } = pageContext as PageContext;

  let header = getTranslation(languageCode as TranslationKey, "header");

  return (
    <BaseWrapper
      initialFeedbackState={{ showSendFeedback: true }}
      crumbLabel={header}
      SEOtitle={header}
      location={location}>
        <h1>{header}</h1>
        <SiteMap {...(pageContext as PageContext)} />
    </BaseWrapper>
  );
};

export default PageTemplate;
