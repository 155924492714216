import { makeTemplate, TranslationKey } from "./languages";

type TranslationType = {
  header?: string;
};

const translations: Record<TranslationKey, TranslationType> = {
  "en-GB": { header: "Information about taxation in the Nordic countries" },
  "da-DK": { header: "Information om beskatning i de nordiske lande" },
  "fi-FI": { header: "Tietoa verotuksesta Pohjoismaissa" },
  "is-IS": { header: "Upplýsingar um skattlagningu á Norðurlöndunum" },
  "no-NO": { header: "Informasjon om skatt i Norden" },
  "sv-SE": { header: "Information om beskattning i de nordiska länderna" },
};

export const getTranslation = (
  languageCode: TranslationKey,
  prop: keyof TranslationType
) => translations[languageCode]?.[prop] ?? translations["en-GB"]?.[prop] ?? "_";
