import React, {
  createContext,
  ReactNode,
  ReactElement,
  useContext,
  useState,
} from "react";

export interface IFeedbackState {
  showSendFeedback: boolean;
}

export const initialState: IFeedbackState = { showSendFeedback: false };

export const FeedbackContext = createContext<IFeedbackState>(initialState);
const FeedbackDispatchContext = createContext<
  ((value: IFeedbackState) => void) | undefined
>(undefined);

export const FeedbackProvider = ({
  children,
  initial,
}: {
  children?: ReactNode;
  initial?: IFeedbackState;
}): ReactElement => {
  const [feedbackState, setFeedbackState] = useState(initial ?? initialState);

  return (
    <FeedbackContext.Provider value={feedbackState}>
      <FeedbackDispatchContext.Provider value={setFeedbackState}>
        {children}
      </FeedbackDispatchContext.Provider>
    </FeedbackContext.Provider>
  );
};

/**
 * Returns feedback state.
 */
export const useFeedbackState = (): IFeedbackState => {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error("useFeedbackState must be used within a FeedbackProvider");
  }
  return context;
};

/**
 * Returns ready to use dispatch method to change feedback state.
 */
export const useFeedbackDispatch = (): ((value: IFeedbackState) => void) => {
  const context = useContext(FeedbackDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useFeedbackDispatch must be used within a FeedbackProvider"
    );
  }
  return context;
};
