import { makeTemplate, TranslationKey } from "./languages";

type TranslationType = {
  header?: string;
};

const translations: Record<TranslationKey, TranslationType> = {
  "en-GB": { header: "Here is information about" },
  "da-DK": { header: "Her finder du information om" },
  "fi-FI": { header: "Tietoa seuraavista aiheista" },
  "is-IS": { header: "Hér eru upplýsingar um" },
  "no-NO": { header: "Her finner du informasjon om" },
  "sv-SE": { header: "Här hittar du information om" },
};

export const getTranslation = (
  languageCode: TranslationKey,
  prop: keyof TranslationType
) => translations[languageCode]?.[prop] ?? translations["en-GB"]?.[prop] ?? "_";
