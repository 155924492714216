import React, { FC, useState } from "react";
import {
  TaxTopic,
  ViewPointTopic,
  Topic,
  SubTopic,
  FooterLink,
  LanguageCode,
  TaxTopicTranslation,
} from "../../../templates/siteMapPageTemplate";
import { TranslationKey } from "../../../translations/languages";
import { getTranslation as getTaxationTranslation } from "../../../translations/taxation";
import { getTranslation as getTopicTranslation } from "../../../translations/topics";
import { getTranslation as getSubTopicTranslation } from "../../../translations/subTopics";
import { getTranslation as getFooterTranslation } from "../../../translations/footer";
import {ViewpointLinks, SingleLayerViewpointLinks} from "./viewpointLinks";
import {
  compareByPresentationOrder,
  groupBy,
  isInternalHref,
} from "../../../utils/misc";
import { convertPageIdToPath } from "../../../utils/path";
import {
  countries,
  CountryKey,
  getCountryLabel
} from "../../../translations/countries";
import { LinkType, localizeHref } from "../../../utils/htmlParser";
import Links, { ListLink } from "../../molecules/links";
import styled from "styled-components";
import { scale, rhythm, colorThemes } from "../../../utils/typography";
import { Separator } from "reakit";
import { darken, transparentize } from "polished";

const {
  separator: { primary: separatorPrimary },
  siteMap: { primary: siteMapPrimary },
} = colorThemes;

type SiteMapProps = {
  languageCode: string;
  taxTopics: TaxTopic[];
  viewpointTopics: ViewPointTopic[];
  topics: Topic[];
  subTopics: SubTopic[];
  footerLinks: FooterLink[];
};

type WithTranslations<T, Tr> = T & { translations: Tr[] };
type WithTranslation<T, Tr> = T & { translation: Tr };

const { fontSize: linkFontSize, lineHeight: linkLineHeight } = scale(-1 / 5);

const Container = styled.div`
  & a {
    font-size: ${linkFontSize};
    line-height: ${linkLineHeight};
  }
`;

const AreaSeparator = styled(Separator)`
  margin: ${rhythm(3)} 0;
  border: 0px;
  border-top: 5px solid ${darken(0.2, separatorPrimary)};
  background: transparent;
`;

const TaxTopicsLinks = styled(Links)`
  border-left: ${rhythm(0.1)} solid ${transparentize(0.3, siteMapPrimary)};
  padding-left: ${rhythm(2)};
  margin-bottom: ${rhythm(2)};
`;

export const findTranslation: any  = <T extends {}, Tr extends LanguageCode>(
  languageCode: string,
  item: WithTranslations<T, Tr>
) => {
  const result = item.translations.find(
    ({ languages_code: { code } }) => code === languageCode
  );
  return result;
};

const findTranslations = <T extends {}, Tr extends LanguageCode>(
  languageCode: string,
  items: WithTranslations<T, Tr>[]
) =>
  items
    .map((c) => {
      const trans = findTranslation(languageCode, c);
      const { translations, ...rest } = c;
      return {
        ...rest,
        translation: trans,
      };
    })
    .filter((c) => !!c.translation);

type TaxTopicLinkType = LinkType & { country: string };

const prioritizeTaxTopicsByCountry = (
  languageCode: string,
  taxTopics: WithTranslation<TaxTopic, TaxTopicTranslation>[]
) => {
  const mappedTopics = taxTopics
    .map((tt) => {
      const countryTopics = Object.keys(countries).map((country) => {
        const href = `${convertPageIdToPath(languageCode, "taxation")}?country=${country}&topic=${tt.id}`;
        return {
          country,
          to: href,
          text: `${tt.translation?.tax_topic_header}`,
        } as TaxTopicLinkType;
      });
      return {
        countryTopics,
        presentationOrder: tt.presentation_order,
      };
    })
    .sort(compareByPresentationOrder);

  const groupedTopics = groupBy(
    mappedTopics.reduce(
      (prev, curr) => [...prev, ...curr.countryTopics],
      ([] as unknown) as TaxTopicLinkType[]
    ),
    "country"
  );

  return groupedTopics as { [K in CountryKey]: TaxTopicLinkType[] };
};

const SiteMap: FC<SiteMapProps> = ({
  languageCode,
  taxTopics,
  viewpointTopics,
  topics,
  subTopics,
  footerLinks,
}) => {
  const [langTaxTopics] = useState(findTranslations(languageCode, taxTopics));
  const [langViewpointTopics] = useState(
    findTranslations(languageCode, viewpointTopics)
  );
  const [langTopics] = useState(
    findTranslations(
      languageCode,
      topics.filter((c) => c.topic_page.id !== "taxation") // Filter taxation page away as it's already listed with full topic details
    )
  );

  const [langSubTopics] = useState(findTranslations(languageCode, subTopics));
  const [langFooterLinks] = useState(
    findTranslations(languageCode, footerLinks)
  );

  const [taxTopicsLinks] = useState(
    prioritizeTaxTopicsByCountry(
      languageCode,
      langTaxTopics as WithTranslation<TaxTopic, TaxTopicTranslation>[]
    )
  );

  const mixedTopicComponents = [
    ...langViewpointTopics.map((vt, ind) => {
      return {
        presentationOrder: vt.presentation_order,
        component: (
          <ViewpointLinks
            {...findTranslation(languageCode, vt.viewpoint)}
            {...vt.translation}
            languageCode={languageCode}
            key={ind}
          />
        ),
      }
    }),
    ...langTopics.map((t, ind) => ({
      presentationOrder: t.presentation_order,
      component: (
        <div key={`topic-${ind}`}>
          <h3>{t.translation?.topic_header}</h3>
          {!t.pages || t.pages.length === 0 &&
            <ListLink
              link={{
                to: convertPageIdToPath(languageCode, t.topic_page.id),
                text: t.translation?.topic_header ?? "",
              }}
              key={`listLink-${ind}`}
            />
          }
          {t.pages && t.pages.length > 0 &&
            <SingleLayerViewpointLinks languageCode={languageCode as TranslationKey} topic={t} ariaLabel={t.translation?.topic_header}/>}
        </div>
      ),
    })),
  ].sort(compareByPresentationOrder);

  const [subTopicLinks] = useState(
    langSubTopics
      .map((st, ind) => {
        return {
          presentationOrder: st.presentation_order,
          component: (
            <ListLink
              link={{
                to: convertPageIdToPath(languageCode, st.sub_topic_page.id),
                text: st.translation?.sub_topic_header ?? "",
              }}
              key={`${languageCode}-${ind}`}
            />
          ),
        };
      })
      .sort(compareByPresentationOrder)
  );

  const [footerLinkComponents] = useState(
    langFooterLinks
      .map((fl, ind) => {
        return {
          presentationOrder: fl.presentation_order,
          component: (
            <ListLink
              link={{
                to: isInternalHref(fl.url)
                  ? localizeHref(fl.url, languageCode)
                  : fl.url,
                text: fl.translation?.link_text ?? "",
              }}
              key={`footerLinks-${ind}`}
            />
          ),
        };
      })
      .sort(compareByPresentationOrder)
  );
    
  return (
    <Container>
      {Object.entries(taxTopicsLinks).map(([country, links]) => {
        const title = `${getTaxationTranslation(languageCode as TranslationKey, "pageHeader")}: ${getCountryLabel(
          languageCode as TranslationKey,
          country as CountryKey
        )}`
        return (
          <div key={`taxtopic-${country}`}>
            <h2 aria-hidden="true">{title}
            </h2>
            <TaxTopicsLinks links={links} aria-label={title} />
          </div>
        );
      })}
      <AreaSeparator orientation="horizontal" />
      <h2>{getTopicTranslation(languageCode as TranslationKey, "header")}</h2>
      {mixedTopicComponents.map((c) => c.component)}
      <AreaSeparator orientation="horizontal" />
      <h2>
        {getSubTopicTranslation(languageCode as TranslationKey, "header")}
      </h2>
      {subTopicLinks.map((c) => c.component)}
      <AreaSeparator orientation="horizontal" />
      <h2>{getFooterTranslation(languageCode as TranslationKey, "header")}</h2>
      {footerLinkComponents.map((c) => c.component)}
    </Container>
  );
};

export default SiteMap;
