import { TranslationKey } from "./languages";

type TranslationType = {
  header?: string;
};

const translations: Record<TranslationKey, TranslationType> = {
  "en-GB": { header: "Links" },
  "da-DK": { header: "Links" },
  "fi-FI": { header: "Linkit" },
  "is-IS": { header: "Tenglar" },
  "no-NO": { header: "Lenker" },
  "sv-SE": { header: "Länkar" },
};

export const getTranslation = (
  languageCode: TranslationKey,
  prop: keyof TranslationType
) => translations[languageCode]?.[prop] ?? translations["en-GB"]?.[prop] ?? "_";
