import React, { FC } from "react";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import { WindowLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { useLanguageState } from "../../utils/contexts/languageContext";
import { getHome, getBreadCrumb } from "../../translations/breadcrumb";
import styled from "styled-components";
import Link from "./link";
import { scale, colorThemes } from "../../utils/typography";
import { rootPagePath } from "../../utils/path";


const {
  breadcrumb: { primary, secondary },
} = colorThemes;

type BreadcrumbProps = {
  location: WindowLocation<unknown>;
  crumbLabel?: string;
};

type Crumb = {
  crumbLabel?: string;
  pathname: string;
};

type DirectusLanguagesType = Array<{ code: string }>;

type DirectusData = {
  directus: { languages: DirectusLanguagesType };
};

type StyledLinkProps = {
  $isCurrent?: boolean;
};

const { fontSize: linkFontSize, lineHeight: linkLineHeight } = scale(-1 / 5);
const StyledLink = styled(Link)<StyledLinkProps>`
  line-height: ${linkLineHeight};
  font-size: ${linkFontSize};
  color: ${({ $isCurrent }) => ($isCurrent ? secondary : primary)};
`;

const CrumbSeparator = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  line-height: ${linkLineHeight};
  font-size: ${linkFontSize};
  color: ${primary};
`;

const Breadcrumb: FC<BreadcrumbProps> = ({ location, crumbLabel, ...rest }) => {
  const data: DirectusData = useStaticQuery(graphql`
    {
      directus {
        languages {
          code
        }
      }
    }
  `);

  const {
    directus: { languages },
  } = data;

  const { languageCode: currentLanguageCode } = useLanguageState();
  const { crumbs: clickTrackingCrumbs } = useBreadcrumb({
    location,
    crumbLabel,
  });

  const filteredCrumbs: Array<Crumb> = clickTrackingCrumbs.filter(
    (c: Crumb) => {
      if (c.crumbLabel === "pages") return false;
      if (languages.findIndex((l) => l.code === c.crumbLabel) > -1)
        return false;
      if (c.pathname.startsWith(`/${rootPagePath}`)) {
        const splitted = c.pathname.split("/");
        const pathLanguageCode = splitted.length > 1 && splitted[2];
        if (pathLanguageCode !== currentLanguageCode) {
          // Check if path part represents a language.
          // If it does it will be removed as it points to a page with previous language.
          if (languages.findIndex((c) => c.code === pathLanguageCode) > -1) {
            return false;
          }
        }
      }
      return true;
    }
  );
  
  let previous: Crumb | null = null;
  filteredCrumbs.forEach((crumb, ind, obj) => {
    if (
      crumb.crumbLabel === "/" || crumb.pathname === "/" ) {
      crumb.crumbLabel = getHome(currentLanguageCode);
    }

    if(previous?.pathname.replace(/[\/]/g, '') === crumb?.pathname.replace(/[\/]/g, '')) {
      obj.splice(ind, 1);
    } else {
      previous = crumb;
    }
  });

  return (
    <nav
      className="breadcrumb"
      aria-label={getBreadCrumb(currentLanguageCode)}
      style={{ marginLeft: "2.5rem", marginTop: "1rem" }}
    >
      <ol className="breadcrumb__list">
        {filteredCrumbs.map((c, i) => {
          return (
            <li className="breadcrumb__item" key={i}>
              <StyledLink
                to={c.pathname || ""}
                aria-current={i === filteredCrumbs.length - 1 ? "page" : null}
                $isCurrent={i === filteredCrumbs.length - 1}
                {...rest}
              >
                {c.crumbLabel}
              </StyledLink>
              {i === filteredCrumbs.length - 1 ? null : (
                <CrumbSeparator aria-hidden="true">{">"}</CrumbSeparator>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
